@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap');

$black: #000;
$white: #fff;
$grey-01: #495965; // button
$grey-02: #333; // text content
$grey-03: #E1E2E6; // separator
$grey-04: #F6F6F6; // background
$blue-01: #0067FF; // link
$blue-02: #0056b3; // link on hover

$ff-montserrat: 'Montserrat', sans-serif;

$mobile: 576px;
$header-height: 88;
$footer-height: 53;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: $ff-montserrat;
  background: $grey-04 url('./assets/background.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
}

.evoting {
  background-color: $white;
  width: 100%;
  max-width: 690px;
  box-shadow: 0 2px 6px 0 rgba(180, 180, 180, .3), 0 10px 20px 0 rgba(184, 184, 184, .19);
  height: 100%;
  
  @media screen and (min-width: $mobile) {
    height: auto;
  }

  &__header {
    padding: 30px 32px;
    box-sizing: border-box;
    border-bottom: 1px solid $grey-03;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-weight: bold;
    color: $grey-01;
    margin: 0;
    font-size: 22px;
  }

  &__lang-btn {
    display: flex;
    align-items: center;
    color: $black;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
  }

  &__flag {
    display: block;
    width: 22px;
    height: 16px;
    margin-right: 3px;
  }

  &__main {
    display: block;
    padding: 30px 32px 40px;
    box-sizing: border-box;
    border-bottom: 1px solid $grey-03;
    color: $grey-02;
    font-size: 14px;
    height: calc(100% - #{$header-height + $footer-height}px);

    @media screen and (min-width: $mobile) {
      height: auto;
    }
  }

  &__title {
    color: $black;
    font-weight: bold;
    display: block;
    margin-bottom: 17px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 23px;
  }

  &__item {
    margin-bottom: 13px;
    line-height: 21px;

    &::before {
      content: "- ";
    }
  }

  &__link {
    display: block;
    color: $blue-01;
    margin-bottom: 37px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $blue-02;
    }
  }

  &__btn {
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    color: $white;
    background-color: $grey-01;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    transition: background-color .3s ease;

    @media screen and (min-width: $mobile) {
      max-width: 160px;      
    }
    
    &:hover {
      background-color: rgba($grey-01, .8);
    }
  }

  &__footer {
    padding: 16px 32px;
    box-sizing: border-box;
  }

  &__footer-content {
    font-size: 13px;
    color: $grey-02;
    display: flex;
    align-items: center;
  }

  &__kdpw-logo {
    display: inline-block;
    width: 54px;
    height: 18px;
    margin-left: 7px;
    transform: translateY(5px);
  }
}